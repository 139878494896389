<template>
    <div class="file_detailsWpr">
        <div class="header">
            <h2>File1.png</h2>
            <ul>
                <li><button><i class="fas fa-link"></i></button></li>
                <li><button><i class="fas fa-download"></i></button></li>
                <li><button><i class="fas fa-trash-alt"></i></button></li>
            </ul>
        </div>
        <div class="thumb">
            <img src="../assets/images/file1.png">
            <ul>
                <li><i class="fas fa-binoculars"></i>12 Views</li>
                <li><i class="fas fa-download"></i>5 Downloads</li>
            </ul>
        </div>
        <div class="total_fileinfo">
            <div class="file_option">
                <h3>File Options</h3>
                <ul>
                    <li>
                        <label :for="contacts" class="switch_option capsule_btn">
                            <h5>Download Permission</h5>
                            <input type="checkbox" :id="contacts" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li>
                        <label :for="contacts" class="switch_option capsule_btn">
                            <h5>Copy Link Permission</h5>
                            <input type="checkbox" :id="contacts" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li>
                        <label :for="contacts" class="switch_option capsule_btn">
                            <h5>View File Permission</h5>
                            <input type="checkbox" :id="contacts" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="file_status">
                <h3>File Details</h3>
                <table>
                    <tr>
                        <th>Type</th>
                        <td>png</td>
                    </tr>
                    <tr>
                        <th>Size</th>
                        <td>110.54 KB</td>
                    </tr>
                    <tr>
                        <th>Owner</th>
                        <td>Me</td>
                    </tr>
                    <tr>
                        <th>Modified</th>
                        <td>02/14/2022 1:32 PM by Me</td>
                    </tr>
                    <tr>
                        <th>Opened</th>
                        <td>02/25/2022 1:38 PM</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>02/14/2022 1:32 PM</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name: 'FileInfo',
}
</script>
