<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Files</h3>
            <div class="right_side">
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
                <button :class="deleteBox.length ? 'delete active' : 'delete'"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file1" class="checkbox">
                        <input type="checkbox" id="file1" value="file1" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>onboardme.png</h4>
                    <label :for="showitem1" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem1" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file2" class="checkbox">
                        <input type="checkbox" id="file2" value="file2" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>test-onboardme.png</h4>
                    <label :for="showitem2" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file3" class="checkbox">
                        <input type="checkbox" id="file3" value="file3" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>onboardme2.png</h4>
                    <label :for="showitem3" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem3" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file4" class="checkbox">
                        <input type="checkbox" id="file4" value="file4" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>onboardme.png</h4>
                    <label :for="showitem4" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem4" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file5" class="checkbox">
                        <input type="checkbox" id="file5" value="file5" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>test-onboardme.png</h4>
                    <label :for="showitem5" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem5" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="file6" class="checkbox">
                        <input type="checkbox" id="file6" value="file6" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>onboardme2.png</h4>
                    <label :for="showitem6" class="switch_option capsule_btn">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem6" hidden>
                        <div><span></span></div>
                    </label>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
        </ul>
        <div v-if="viewForm" class="edit_modal">
            <div class="edit_section mt-4 mb-3 p-0">
                <h2 class="title_header">File1.png <button class="close_btn" @click="viewForm = false"><i class="fas fa-times"></i></button></h2>
                <FileInfo/>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import FileInfo from './FileInfo.vue';
export default{
    name: 'ProfileFiles',
    components:{
        PageItem,
        NotesCard,
        FileInfo
    },
    data(){
        return{
            viewForm: false,
            deleteBox: [],
            actionObj: [
                {
                    icon: "fa-link",
                    label: "Copy",
                },
                {
                    icon: "fa-search-plus",
                    label: "View",
                },
                {
                    icon: "fa-download",
                    label: "Download",
                }
            ]
        }
    },
    methods:{
        viewFunc(){
            this.viewForm = true;
        }
    }
}
</script>
